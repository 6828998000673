import React from "react";
import Modal from "@material-ui/core/Modal";
import QuoteStepperHorizontal from "./quote.stepper.component";
import { Mobile, Tablet } from "../../utils/media-queries";
import { withStyles, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { currencies } from "../currencies/currencies";
import { displayTotalAmount } from "../../utils/utils";

function getModalStyle() {
  // const top = 0;
  // const left = 0;

  return {
    top: "2vh", //`${top}%`,
    left: "1.5vw", //`${left}%`,
    // transform: `translate(-${top}%, -${left}%)`,
  };
}

var ua = navigator.userAgent.match(
    /(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i,
  ),
  browser;
if (
  navigator.userAgent.match(/Edge/i) ||
  navigator.userAgent.match(/Trident.*rv[ :]*11\./i)
) {
  browser = "msie";
} else {
  if (browser) {
    browser = ua[1].toLowerCase();
  } else {
    browser = "facebook";
  }
}

const styles = (theme) => ({
  paper: {
    position: "absolute",
    width: "96vw",
    height:
      browser === "firefox" || browser === "msie"
        ? "100%"
        : "-webkit-fill-available",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    backgroundImage:
      "url(/assets/images/city.svg), url(/assets/images/city.svg)",
    backgroundRepeat: "no-repeat, no-repeat",
    backgroundSize: "20%, 30%",
    backgroundPosition: "2% 10%, 80% 90%",
  },
});

class SimpleModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      open: false,
      modalStyle: getModalStyle(),
      payButtonEnbabled: false,
      cardInfo: {},
      startPayment: false,
    };

    this.company = props.company;

    this.setStep = this.setStep.bind(this);
    this.togglePayButton = this.togglePayButton.bind(this);
    this.submitCardInfo = this.submitCardInfo.bind(this);
    this.saveCardInfo = this.saveCardInfo.bind(this);
  }

  chargeIt = null;

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  payStep = (e) => {
    this.setState({ step: 1 });
  };

  setStep = (val) => {
    this.setState({ step: val });
  };

  submitCardInfo = () => {
    const { payButtonEnbabled, cardInfo } = this.state;
    const price =
      this.props.term === "total" ? this.props.allTotal : this.props.firstTotal;

    if (
      (!payButtonEnbabled && !this.props.bypassPayment) ||
      (this.props.bypassPayment && price === 0)
    )
      return false;

    if (this.props.cardProcessor === "lawnbot-credit-card-vault") {
      this.chargeIt();
    } else {
      this.props.submitPayment("card", cardInfo);
    }
  };

  saveCardInfo = (cardInfo) => {
    this.setState({ cardInfo });
  };

  togglePayButton = (buttonState) => {
    if (buttonState === "enable") {
      this.setState({ payButtonEnbabled: true });
    } else {
      this.setState({ payButtonEnbabled: false });
    }
  };

  chargePciProxy = (fn) => {
    this.chargeIt = fn;
  };

  render() {
    let currentCurrency = "$";
    currencies.forEach((currency) => {
      if (currency.name === this.props.currency) {
        currentCurrency = currency.symbol;
      }
    });
    const { classes } = this.props;
    const price =
      this.props.term === "total" ? this.props.allTotal : this.props.firstTotal;
    const taxAmount = price * (this.props.serviceTax / 100);
    const totalPrice = price + taxAmount;

    const selectedItems = [
      ...this.props.priceBlocks.filter((t) => t.selected),
      ...this.props.availableServices.filter((t) => t.selected),
    ];

    const urlParams = new URLSearchParams(window.location.search);
    const preview = urlParams.get("preview");
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.open}
          onClose={this.handleOpen}
        >
          <div style={this.state.modalStyle} className={classes.paper}>
            <QuoteStepperHorizontal
              {...this.props}
              registerCharge={this.chargePciProxy}
              currentStep={this.state.step}
              setStep={this.setStep}
              togglePayButton={this.togglePayButton}
              saveCardInfo={this.saveCardInfo}
            />
            {
              <div
                style={{
                  flex: "0 0 auto",
                  display: "flex",
                  bottom: "1vh",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  position: "absolute",
                  width: "100%",
                }}
              >
                <Mobile>
                  {this.props.bypassPayment ? (
                    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                    <div
                      onClick={this.submitCardInfo}
                      style={{
                        width: "100%",
                        height: 55,
                        backgroundColor:
                          totalPrice > 0 && !this.props.charging
                            ? "#1565C0"
                            : "#ccc",
                        paddingTop: 25,
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        fontFamily: `'Open Sans', sans-serif`,
                        fontSize: 21,
                        fontWeight: "bold",
                        color: "white",
                        cursor: "pointer",
                        textAlign: "center",
                      }}
                    >
                      {this.props.charging ? (
                        <CircularProgress />
                      ) : (
                        <span style={{ width: "100%" }}>⚡ BUY NOW</span>
                      )}
                    </div>
                  ) : (
                    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                    <div
                      onClick={
                        this.state.step === 0
                          ? this.payStep
                          : this.submitCardInfo
                      }
                      style={{
                        width: "100%",
                        height: 55,
                        backgroundColor:
                          this.state.step === 0
                            ? "#1565C0"
                            : this.state.payButtonEnbabled &&
                                !this.props.charging
                              ? "#1565C0"
                              : "#ccc",
                        paddingTop: 25,
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        fontFamily: `'Open Sans', sans-serif`,
                        fontSize: 21,
                        fontWeight: "bold",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      {this.props.charging ? <CircularProgress /> : null}
                      {this.state.step === 0 &&
                      this.props.term === "application" ? (
                        <span>Sign Up</span>
                      ) : null}
                      {this.state.step === 0 &&
                      this.props.term !== "application" ? (
                        <span>Sign Up</span>
                      ) : null}

                      {this.state.step === 1 &&
                      this.props.term === "application" ? (
                        <span>Checkout</span>
                      ) : null}
                      {this.state.step === 1 &&
                      this.props.term !== "application" ? (
                        <span>Sign Up</span>
                      ) : null}
                    </div>
                  )}
                </Mobile>
                <Tablet>
                  {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                  <div
                    onClick={this.payStep}
                    style={{
                      width: "100%",
                      height: 55,
                      backgroundColor: "#1565C0",
                      paddingTop: 25,
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      fontFamily: `'Open Sans', sans-serif`,
                      fontSize: 21,
                      fontWeight: "bold",
                      color: "white",
                      cursor: "pointer",
                    }}
                  >
                    <span>Sign Up</span>
                  </div>
                </Tablet>
              </div>
            }
          </div>
        </Modal>
      </div>
    );
  }
}

SimpleModal.propTypes = {
  submitPayment: PropTypes.func,
};
export default withStyles(styles)(SimpleModal);
