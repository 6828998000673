import React, { Component } from "react";
import "./App.css";

import ChatBot from "@workwave_it/react-simple-chatbot";
import styled from "styled-components";

import { generateStepTrigger } from "./utils/utils";
import * as fetch from "fetch";
import { ProgramSelectComponent } from "./components/program-select.component";
import { ServiceBotHeader } from "./components/service-bot-header.component";
import { LeadTimeoutComponent } from "./components/lead-timeout.component";
import SimpleModal from "./components/quote/quote.modal.component";
import { generateLawnServiceSteps } from "./steps/lawn-services";
import welcomeSteps from "./steps/welcome";
import gettingStarted from "./utils/getting-started";
import newCustomerOnboard from "./steps/new-customer";
import { generateServiceQuestionsSteps } from "./steps/after-purchase-questions";
import { generateObjectionSteps } from "./steps/objections";
import SqFtSlider from "./components/widgets/slider.widget.component";
import LoadingScreen from "./components/loading/loading-screen";
import { Desktop, Mobile, Tablet } from "./utils/media-queries";
import { CalculatingQuoteComponent } from "./components/calculating.component";

import media from "./components/loading/media.conf";

import * as na_parser from "parse-address-string";
import * as _ from "lodash";
import MeasureItModal from "./components/modals/measur.it.modal";
import { HtmlMessageComponent } from "./components/html-message.component";
import { translateState } from "./utils/us-states";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import { priceCalculations } from "@workwave_it/lawnbot-pricing-calculations";
import servManSteps, { buildCategoryOptions } from "./steps/servman";
import { PROGRAM_TYPES } from "./constants";

const ChatContainer = styled.div`

  ${media.largest`
      height: 100vh; 
      backgroundColor: white; 
      width: 50%; 
      margin: auto;
      overflow: hidden;
      & div.chatbot {
        width: 103.5%;
      };
  `}

  ${media.desktop`
    height: 100vh; 
    backgroundColor: white; 
    width: 50%; 
    margin: auto;
    overflow: hidden;
    & div.chatbot {
      width: 103.5%;
    };
  `}

  ${media.tablet`
    height: 98vh; 
    backgroundColor: white; 
    width: 100%; 
    margin: auto;     
    paddingBottom: 80px;
    & div.chatbot {
      width: 103%;
    };
  `}

  ${media.phone`
    height: 98vh;
    backgroundColor: white;
    width: 100%;
    margin: auto;
  `}
`;

class App extends Component {
  currentQuestion = {};
  // dashboardApi = `http://servman-new-elb-851547788.us-east-2.elb.amazonaws.com:3006`;
  // dashboardApi = "http://localhost:3006";
  dashboardApi =
    process.env.REACT_APP_DASHBOARD_API || "https://api.test.lawnbot.biz";
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  };

  // dashboardApi = `http://servman-new-elb-851547788.us-east-2.elb.amazonaws.com:3006`;
  // dashboardApi = "http://localhost:3000";
  // dashboardApi = `https://api.test.lawnbot.biz`;

  // company info
  companyId = "";
  currency = "";
  botId = "";
  companyName = "";
  instaQuoteMessage = "";
  companyLogo = "";
  companyAvatar = "";
  companyTagLine = "";
  botName = "";
  companyValueDescription = "";
  companyValueAddImage = "";
  learnMoreSteps = [];
  objectionSteps = [];
  serviceQuestionsSteps = [];
  taxOverride = false;
  // customer info
  pricingOption = "monthly";
  totalLotSize = 5000;
  customerFirstName = "Erik";
  customerLastName = "Alburg";

  answers = {};

  companyMinQuotableSize = 500;

  bubbleStyle = {
    display: "flex",
    padding: 16,
    alignSelf: "stretch",
    borderRadius: 16,
    backgroundColor: "#fff",
    color: "#000",
    width: "80%",
    maxWidth: "80%",
    fontSize: 16,
    lineHeight: 1.2,
    fontFamily: "Inter",
  };

  bubbleStyle_old = {
    boxShadow: "0 1px 28px 0 rgba(90,60,122,.22)",
    minWidth: 42,
    borderRadius: 30,
    backgroundColor: "rgb(228, 228, 235)",
    boxSizing: "content-box",
    minHeight: "1.35em",
    display: "inline-block",
    maxWidth: "82%",
    overflow: "hidden",
    padding: "16px 24px",
    transition: "opacity .5s,background-color .75s,color .75s",
    color: "black",
    marginBottom: 35,
    marginRight: "10%",
    fontFamily: `'Open Sans', sans-serif`,
  };

  desktopStyle = {
    height: "100vh",
    backgroundColor: "white",
    width: "50%",
    margin: "auto",
  };
  mobileStyle = {
    height: "98vh",
    backgroundColor: "white",
    width: "100%",
    margin: "auto",
  };
  tabletStyle = {
    height: "98vh",
    backgroundColor: "white",
    width: "70%",
    margin: "auto",
    paddingBottom: 80,
  };
  overFlowHiddenStyle = { overflow: "hidden" };

  programSteps = [];

  priceBlocks = [];

  availableServices = [];

  selectedServices = [];
  activatedServices = [];
  onboarding = false;
  preview = false;
  dsteps = [];

  ga = null;

  redirectToPortal = false;

  startServiceQuestions() {
    const { selectedPrograms, steps } = this.state;
    if (selectedPrograms.length) {
      const clonePrograms = [...selectedPrograms];
      let nextEntry = "get-started";
      for (let p = 0; p < clonePrograms.length; p++) {
        const program = selectedPrograms.shift();
        const entry = `${this.getSericeEntryId(program)}-entry`;

        const foundStep = steps.find((s) => s.id === entry);

        if (foundStep) {
          this.setState({ selectedPrograms });
          nextEntry = entry;
          break;
        }
      }
      if (nextEntry === "get-started") {
        // console.log('last question here');
        this.updateCalculatingStep();
        return "next-service";
      }
      return nextEntry;
    }
    const t = this.updateCalculatingStep();
    return t;
  }

  getSericeEntryId(program) {
    let entrypoint = "";
    switch (program) {
      case "Fertilizer & Weed Control":
        entrypoint = "fertnweed";
        break;
      case "Mosquito Control":
        entrypoint = "mosquito";
        break;
      case "Mowing":
        entrypoint = "mowing";
        break;
      case "Lawn Seeding":
        entrypoint = "lawn-seeding";
        break;
      case "Irrigation Services":
        entrypoint = "irrigation";
        break;
      case "Perimeter Pest Control":
        entrypoint = "perimeter-pest-control";
        break;
      case "Pest Control":
        entrypoint = "pest-control";
        break;
      case "Tick":
        entrypoint = "tick";
        break;
      case "Mosquito & Tick":
        entrypoint = "mosquito-and-tick";
        break;
      case "Flea & Tick":
        entrypoint = "flea-and-tick";
        break;
      case "Flea & Ant":
        entrypoint = "flea-and-ant";
        break;
      case "Covid":
        entrypoint = "covid";
        break;
      case "Snow Removal":
        entrypoint = "snow";
        break;
      case "Aeration":
        entrypoint = "aeration";
        break;
      case "Aeration & Overseed":
        entrypoint = "aeration-and-overseed";
        break;
      case "Liquid Aeration":
        entrypoint = "liquid-aeration";
        break;
      case "Army Worm":
        entrypoint = "army-worm";
        break;
      case "Fire Ant":
        entrypoint = "fire-ant";
        break;
      case "Air 8":
        entrypoint = "air-8";
        break;
      case "Yard Clean Up":
        entrypoint = "yard-clean-up";
        break;
      case "Spotted Lantern Fly":
        entrypoint = "spotted-lantern-fly";
        break;
      case "Thatch":
        entrypoint = "thatch";
        break;
      case "Mole Control":
        entrypoint = "mole-control";
        break;
      case "Bin Cleaning":
        entrypoint = "bin-cleaning";
        break;
      case "Pet Waste":
        entrypoint = "pet-waste";
        break;
      case PROGRAM_TYPES.COMMERCIAL_CLEANING:
        entrypoint = "commercial-cleaning";
        break;
      case PROGRAM_TYPES.RESIDENTIAL_CLEANING:
        entrypoint = "residential-cleaning";
        break;
      default:
    }

    return entrypoint;
  }

  constructor(props) {
    super(props);
    this.state = {
      steps: [],
      priceBlocks: [],
      term: "application",
      availableServices: this.availableServices,
      allTotal: 0,
      firstTotal: 0,
      regularTotal: 0,
      discout: 0,
      modalOpen: false,
      companyLoading: true,
      loading: true,
      loadedPriceBlocks: false,
      noPII: true,
      chatStyle: this.desktopStyle,
      deviceType: "desktop",
      overflowStyle: this.overFlowHiddenStyle,
      customerRecord: null,
      customerLat: 0,
      customerLng: 0,
      customerAddress: "",
      customerAddress2: "",
      customerCity: "",
      customerState: "",
      customerZip: "",
      phonenumber: null,
      customerFirstName: null,
      customerLastName: null,
      customerEmail: "",
      source: null,
      streetImg: null,
      aerialImg: null,
      chatId: null,
      charging: false,
      estimateModalOpened: false,
      measuritImg: null,
      crmId: null,
      paymentToken: null,
      currency: "",
    };

    this.updatePriceBlocks = this.updatePriceBlocks.bind(this);
    this.updatePricingTerms = this.updatePricingTerms.bind(this);
    this.submitPayment = this.submitPayment.bind(this);
    this.lookupCustomer = this.lookupCustomer.bind(this);
    this.setPriceBlocks = this.setPriceBlocks.bind(this);
    this.thinkAboutIt = this.thinkAboutIt.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.lookupZipCode = this.lookupZipCode.bind(this);
    this.lookupPostalTaxRate = this.lookupPostalTaxRate.bind(this);
    this.checkAlreadyQuoted = this.checkAlreadyQuoted.bind(this);
    this.startServiceQuestions = this.startServiceQuestions.bind(this);
    this.getSericeEntryId = this.getSericeEntryId.bind(this);
    this._bot = React.createRef();
    this._modal = React.createRef();
    this._measureit = React.createRef();
  }

  compileSteps() {
    const steps = [
      ...welcomeSteps(this),
      ...gettingStarted(this),
      ...newCustomerOnboard(this),
      ...this.programSteps,
      ...servManSteps(this),
    ];

    const hasLearnMore = steps.find(
      (s) => s.id === "company-learn-more-description",
    );

    if (!hasLearnMore) {
      steps.push({
        id: "company-learn-more-description",
        message: "Let's Get Started!",
        trigger: "ready-question",
      });
    }
    this.setState({ steps });
  }

  lookupCustomer = (customerNumber = null) => {
    let number = customerNumber;
    if (number) {
      this.setState({ phonenumber: number });
    } else {
      if (this.state.source) {
      } else {
        number = this.state.phonenumber;
      }
    }
    if (number) {
      fetch.fetchUrl(
        `${this.dashboardApi}/v1/company/${this.companyId}/customer/lookup?phonenumber=${number}`,
        {},
        (error, meta, body) => {
          const statusCode = meta.status;
          if (statusCode === 404) {
            console.log("no user found");
          } else {
            const str_body = body.toString();
            let jsonBody = {};
            try {
              jsonBody = JSON.parse(str_body);
              if (jsonBody.status === 200) {
                this.setState({ noPII: false });
              }
              if (jsonBody.record) {
                const keys = Object.keys(jsonBody.record);
                if (keys.length) {
                  this.setCustomerRecord(jsonBody).then(async () => {
                    this.compileSteps();
                    this.loadSteps();
                  });
                }
              }
            } catch (e) {
              console.log("error tracking", e);
            }
          }
        },
      );
    } else {
      const self = this;
      setTimeout(() => {
        // self.setState({estimateModalOpened: true});
        this.compileSteps();
        self.loadSteps();
        // this._measureit.current.handleOpen();
      }, 4000);
    }
  };

  lookupCompany() {
    return new Promise((res, rej) => {
      let path = window.location.pathname.replace(/[/\s]/, "");
      const urlParams = new URLSearchParams(window.location.search);
      const preview = urlParams.get("preview");
      const onboarding = urlParams.get("onboarding");
      const cookiesDisabled = urlParams.get("cookiesDisabled");
      this.onboarding = onboarding;
      this.preview = preview;
      this.cookiesDisabled = cookiesDisabled;
      if (!path.length || path === "index.html") {
        path =
          "be26004d-14f8-4d84-bc96-9ccccc9d99c3/7465f865-1e9d-479e-a479-d2d054cb6463";
      }
      const [companyId, botId] = path.split("/");
      this.companyId = companyId;
      this.botId = botId;
      const url = onboarding
        ? `/v1/company/${companyId}/onboarding`
        : `/v1/company/${companyId}/bot/${botId}${
            preview ? "?preview=true" : ""
          }`;
      fetch.fetchUrl(this.dashboardApi + url, {}, (error, meta, body) => {
        const statusCode = meta.status;
        if (statusCode === 404) {
          console.log("no company found");
        } else {
          const str_body = body.toString();
          let jsonBody = {};
          try {
            jsonBody = JSON.parse(str_body);
            this.isLive = jsonBody.isLive || false;
            this.creditCardPaymentType = jsonBody.paymentType || "stripe";
            this.companyId = jsonBody.id;
            this.currency = jsonBody.currency;
            this.taxOverride = jsonBody.taxOverride;
            this.companyName = jsonBody.companyName;
            this.bypassPayment = jsonBody.bypassPayment || false;
            this.companyPhone = jsonBody.companyPhone;
            this.companyLogo = jsonBody.companyLogo;
            this.companyAvatar = jsonBody.companyAvatar;
            this.companyTagLine = jsonBody.tagLine;
            this.botName = jsonBody.botName;
            this.companyPhone = jsonBody.companyPhone;
            this.sqftEstimateSource =
              jsonBody.botType === "pest"
                ? "home"
                : jsonBody.sqftEstimateSource || "measur-it";
            this.instaQuoteMessage = jsonBody.instaQuoteMessage;
            this.availableServices = jsonBody.availableServiceTypes;
            this.botType = jsonBody.botType;
            this.companyValueDescription = jsonBody.valueAddDescription;
            this.companyAboutUsVideo = jsonBody.aboutUsVideo;
            this.companyValueAddImage = jsonBody.valueAddImage;
            this.companyMinQuotableSize =
              typeof jsonBody.minQuotableSize !== "undefined"
                ? jsonBody.minQuotableSize
                : 500;
            this.companyMaxQuotableSize = jsonBody.maxQuotableSize || 40000;
            this.serviceTax = jsonBody.serviceTax || 0.0;
            this.lookupZipCodeTax = jsonBody.lookupZipCodeTax || false;
            this.zipCodes = jsonBody.zipCodes || [];
            this.sqFtPercentage =
              this.sqftEstimateSource === "zillow"
                ? 1 - jsonBody.sqFtPercentage / 100 || 0.4
                : 1.0;
            this.dontTaxZipCodes = jsonBody.dontTaxZipCodes;

            this.autoPayAvailable =
              jsonBody.autoPayAvailable === null ||
              typeof jsonBody.autoPayAvailable === "undefined"
                ? true
                : jsonBody.autoPayAvailable;
            this.prePayAvailable =
              jsonBody.prePayAvailable === null ||
              typeof jsonBody.prePayAvailable === "undefined"
                ? true
                : jsonBody.prePayAvailable;

            /// handle status 8/9 client redirects
            this.isCurrentClient = false;
            this.currentClientRedirectUrl = null;

            let term = "application";
            if (!this.autoPayAvailable && this.prePayAvailable) {
              term = "total";
            }

            this.hideSummary =
              jsonBody.hideSummary === null ? false : jsonBody.hideSummary;
            this.autoPayAlternativeLabel =
              jsonBody.autoPayAlternativeLabel || null;
            this.autoPayPriceMethod =
              jsonBody.autoPayPriceMethod || "first-application-payment";
            this.autoPayMonthlyPricePeriod =
              jsonBody.autoPayMonthlyPricePeriod || 12;

            this.botQuoteAnalyzingGif =
              jsonBody.botQuoteAnalyzingGif || "standard";
            this.botSpeed = jsonBody.botSpeed || 1750;

            this.quoteModalImage = jsonBody.quoteModalImage;
            if (this.quoteModalImage.indexOf("?") !== -1) {
              this.quoteModalImage = this.quoteModalImage.split("?")[0];
            }
            this.learnMoreSteps = jsonBody.learnMoreSteps || [];
            this.objectionSteps =
              jsonBody.objectionSteps || generateObjectionSteps(this);
            this.serviceQuestionsSteps =
              jsonBody.serviceQuestionsSteps ||
              generateServiceQuestionsSteps(this);
            this.probingQuestionsSteps =
              jsonBody.probingQuestions || generateLawnServiceSteps(this);
            this.preModalQuestions = jsonBody.preModalQuestions || [];

            this.termsOfService = jsonBody.termsOfService || null;

            /// crm
            this.crmSolution = jsonBody.crmSolution || null;
            this.crmLeadSolution = jsonBody.crmLeadSolution || null;
            this.crmPortalUrl = jsonBody.crmPortalUrl || null;

            this.crmLeadTimeout = React.createRef(new Date().getTime() / 1000);

            /// fallback
            this.zillowFallback =
              typeof jsonBody.zillowFallback === "undefined"
                ? false
                : jsonBody.zillowFallback;

            /// get redirect values
            this.willRedirect =
              typeof jsonBody.willRedirect === "undefined"
                ? false
                : jsonBody.willRedirect;
            this.redirectUrl =
              typeof jsonBody.redirectUrl === "undefined"
                ? null
                : jsonBody.redirectUrl;
            this.purchaseMade = false;

            this.skipProbingQuestions =
              typeof jsonBody.skipProbingQuestions === "undefined"
                ? false
                : jsonBody.skipProbingQuestions;

            this.salutation = jsonBody.salutation || "Welcome";
            this.greeting = jsonBody.greeting || null;

            this.isMetric =
              typeof jsonBody.isMetric === "undefined"
                ? true
                : jsonBody.isMetric;
            this.measurementMode =
              typeof jsonBody.measurementMode === "undefined"
                ? "perimeter"
                : jsonBody.measurementMode;

            this.serviceAgreementContent =
              jsonBody.serviceAgreementContent || null;
            this.noServiceAgreementContent =
              jsonBody.noServiceAgreementContent || null;

            this.serviceAgreementTellMeMore =
              jsonBody.serviceAgreementTellMeMore || null;
            this.emergencyTellMeMore = jsonBody.emergencyTellMeMore || null;

            /// servman items
            this.gettingStartedText = jsonBody.gettingStartedText;
            this.hideArielAndStreetView =
              typeof jsonBody.hideArielAndStreetView === "undefined"
                ? false
                : jsonBody.hideArielAndStreetView;
            this.customSchedulingVerbiage =
              jsonBody.customSchedulingVerbiage ||
              "<<Scheduled Date>> during the  <<Service Window Desc>>";
            // this.setupAvailableServices();
            // const self = this;
            // self.setState({companyLoading: false}, () => {
            //   this.lookupCustomer();
            // });

            this.setState({ companyLoading: false, term }, () => {
              res();
              // setTimeout(() => {
              //   self._modal.current.handleOpen()
              // }, 5000)
            });
          } catch (e) {
            console.log("error tracking", e);
            this.setState({ companyLoading: false });
            rej();
          }
        }
      });
    });
  }

  setupAvailableServices() {
    this.programSteps = [
      {
        id: "intro-program",
        message: "What services are you interested in today?",
        trigger: "select-programs",
      },
      {
        id: "select-programs",
        component: (
          <ProgramSelectComponent available_programs={this.availableServices} />
        ),
        asMessage: false,
        waitAction: true,
        hideInput: true,
        dispatchMessage: (val) => {
          this.recordChatRecord({ serviceTypes: val });
        },
        trigger: (val) => {
          return "confirm-programs";
        },
      },
      {
        id: "confirm-programs",
        message: ({ previousValue, steps }) => {
          if (previousValue) {
            this.setState({
              selectedPrograms: [...previousValue],
              selectedServices: [...previousValue],
            });

            this.activatedServices = [...previousValue];
            return `You selected ${previousValue.join(", ")}!`;
          }
          return "After reviewing your property, we feel it would be best for us to quote your property by phone or in-person.  Do you have any questions for our team?";
        },
        trigger: (val) => {
          const { selectedPrograms, steps } = this.state;
          if (!selectedPrograms) return "sqft-too-big";

          const hasIrrigation = !!selectedPrograms.find(
            (i) => i === PROGRAM_TYPES.IRRIGATION_SERVICES,
          );
          const hasBinCleaning = !!selectedPrograms.find(
            (i) => i === PROGRAM_TYPES.BIN_CLEANING,
          );

          const hasPetWaste = !!selectedPrograms.find((i) => i === "Pet Waste");
          const hasSqftServices = !!selectedPrograms.filter(
            (i) =>
              i !== PROGRAM_TYPES.IRRIGATION_SERVICES &&
              i !== PROGRAM_TYPES.BIN_CLEANING &&
              i !== PROGRAM_TYPES.PET_WASTE,
          ).length;
          if (this.sqftEstimateSource === "measur-it" && hasSqftServices) {
            // TODO - add measur.it flow

            return "measur-it-okay-great";
          }
          if (this.sqftEstimateSource === "home") {
            // this prompts the user to enter their sqft and then the probing questions are asked.
            // sqftEstimateSource === "home" occurs for pest bots (internal bots)
            return "home-okay-great";
          }
          if (hasIrrigation || hasBinCleaning || hasPetWaste) {
            const dest = this.startServiceQuestions();
            return dest;
          }
          const questions = selectedPrograms
            .map((i) => {
              const entry = `${this.getSericeEntryId(i)}-entry`;
              const item = steps.find((t) => t.id === entry);
              return item;
            })
            .filter((p) => p);

          if (this.preModalQuestions.length) {
            return "pre-modal-entry";
          }
          if (questions.length && !this.skipProbingQuestions) {
            return "ask-quote-now";
          }
          this.updateCalculatingStep();
          return "no-questions-open-quote";
        },
      },
      {
        id: "pre-modal-exit",
        message: "Thanks for answering our questions",
        trigger: "ask-quote-now",
      },
      {
        id: "ask-quote-now",
        message:
          "Would you like to get an Instant Quote now or customize your quote?",
        trigger: "get-quote-now",
      },
      {
        id: "open-quote-now",
        message: "Please wait...",
        trigger: () => {
          this.updateCalculatingStep();
          return "no-questions-open-quote";
        },
      },
      {
        id: "get-quote-now",
        options: [
          {
            value: "get-quote",
            label: "Get Quote Now",
            hideText: true,
            trigger: () => {
              const t = this.updateCalculatingStep();
              return t;
            },
          },
          {
            value: "customize-quote",
            label: "Customize My Quote",
            hideText: true,
            trigger: () => {
              const dest = this.startServiceQuestions();
              return dest;
            },
          },
        ],
        optionType: "default",
      },
      {
        id: "we-will-now-caculate",
        message: "We will now recommend the best possible programs.",
        trigger: "get-started",
      },
      {
        id: "next-service",
        message: () => {
          const { selectedPrograms } = this.state;

          if (selectedPrograms.length) {
            const found = [];

            if (found.length) {
              return `Okay.... now let me ask you questions about ${found[0]}`;
            }
            return "Thank you for answering our questions.";
          }
          return "Thank you for answering our questions.";
        },
        trigger: () => {
          const { selectedPrograms, steps } = this.state;
          if (selectedPrograms.length) {
            const clonePrograms = [...selectedPrograms];

            const entries = clonePrograms
              .map((i) => `${this.getSericeEntryId(i)}-entry`)
              .map((i) => {
                return steps.find((p) => p.id === i);
              })
              .filter((i) => i);

            if (entries.length) {
              const dest = this.startServiceQuestions();
              return dest;
            }
            this.updateCalculatingStep();
            return "we-will-now-caculate";
          }

          this.updateCalculatingStep();
          return "we-will-now-caculate";
        },
      },
      {
        id: "sqft-slider",
        component: (
          <SqFtSlider
            image={() => {
              return `${
                process.env.REACT_APP_DASHBOARD_API
              }/v1/property/aerial?lat=${this.getLat()}&lng=${this.getLng()}`;
            }}
            totalLotSize={() => {
              return this.state.totalLotSize;
            }}
            adjustedLotSize={() => {
              return this.state.adjustedLotSize;
            }}
          />
        ),
        waitAction: true,
        trigger: ({ value }) => {
          this.adjustedLotSize = value
            .replace(/sq\s*ft/gi, "")
            .replace(/,/g, "");
          this.adjustedLotSize = Number.parseInt(this.adjustedLotSize);
          return "intro-program";
        },
      },
    ];
  }

  updateMeasurImgStep() {
    return new Promise((res, rej) => {
      const step = {
        id: "display-measurit-image",
        component: <HtmlMessageComponent />,
        inputAttributes: {
          html: `<img src=${this.state.measuritImg} style="width: 100%" />`,
        },
        trigger: "you-colored-lawn-size",
      };

      this._bot.current.addStepToStack(step);
      setTimeout(() => {
        res();
      }, 300);
    });
  }

  updateCalculatingStep() {
    const step = {
      id: "get-started",
      component: (
        <CalculatingQuoteComponent
          onboarding={this.onboarding}
          preview={this.preview}
          currency={this.currency}
          setPriceBlocks={this.setPriceBlocks}
          apiURL={this.dashboardApi}
          selectedServices={this.state.selectedServices}
          answers={this.answers}
          ga={this.ga}
          companyId={this.companyId}
          botId={this.botId}
          totalLotSize={this.state.totalLotSize}
          adjustedLotSize={this.state.adjustedLotSize}
          chatId={this.state.chatId}
          customerZip={`${this.state.customerZip}`}
          loadingGif={this.botQuoteAnalyzingGif}
          bot={this._bot.current}
        />
      ),
      asMessage: false,
      waitAction: true,
      dispatchMessage: () => {},
      trigger: (val) => {
        const any = [
          ...this.state.priceBlocks,
          ...this.state.availableServices,
        ];
        if (any.length) {
          this._modal.current.handleOpen();
          return "now-opening-quote";
        }
        return "sqft-too-big";
      },
    };
    this._bot.current.addStepToStack(step);

    return "get-started";
  }

  setPriceBlocks(blocks) {
    const priceBlocks = blocks.filter((i) => !i.isUpsell);
    const services = blocks.filter((i) => i.isUpsell);
    this.setState({
      loadedPriceBlocks: true,
      priceBlocks: priceBlocks,
      availableServices: services,
    });
    this.calculateTotalCosts();
  }

  lookupZipCode(zip, lookupPostalTaxRate) {
    if (!zip) return;
    fetch.fetchUrl(
      `${this.dashboardApi}/v1/property/lookup/zip?zip=${zip}`,
      {},
      (error, meta, body) => {
        const statusCode = meta.status;
        if (statusCode === 404) {
          console.log("no user found");
        } else {
          const str_body = body.toString();
          let jsonBody = {};
          try {
            jsonBody = JSON.parse(str_body);
            const payload = {
              customerCity: jsonBody.city,
              customerState: jsonBody.state,
            };
            if (lookupPostalTaxRate) {
              lookupPostalTaxRate(zip, jsonBody.city);
            }
            this.setState(payload);
          } catch (e) {
            console.log("error tracking", e);
          }
        }
      },
    );
  }

  toDataURL = (url, callback) => {
    return new Promise((res, rej) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        if (xhr.status === 404) {
          res(null);
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            res(reader.result);
          };

          reader.readAsDataURL(xhr.response);
        }
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  };

  async setImageState(params) {
    const { customerLat, customerLng } = params;
    setTimeout(() => {
      this.recordAddressLocation({
        location: { lat: customerLat, lon: customerLng },
      });
    }, 3000);

    const aerialImg = await this.toDataURL(
      `${this.dashboardApi}/v1/property/aerial?lat=${params.customerLat}&lng=${params.customerLng}`,
    );
    const streetImg = await this.toDataURL(
      `${this.dashboardApi}/v1/property/street?city=${params.customerCity}&state=${params.customerState}&zip=${params.customerZip}&address=${params.customerAddress}`,
    );
    this.setState({ streetImg, aerialImg });
  }

  async lookupCategoryProblems(params = {}) {
    // biome-ignore lint/suspicious/noAsyncPromiseExecutor: ignoring this for now
    return new Promise(async (res, rej) => {
      const serviceUrl = `${this.dashboardApi}/v1/servman/problems`;
      fetch.fetchUrl(
        serviceUrl,
        {
          method: "Post",
          payload: JSON.stringify(params),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
        (error, meta, body) => {
          const statusCode = meta.status;
          if (statusCode === 404) {
            res(null);
          } else {
            const str_body = body.toString();
            let jsonBody = {};

            try {
              jsonBody = JSON.parse(str_body);
              res(jsonBody);
            } catch (e) {
              console.log("category questions lookup error", e);
              rej(e);
            }
          }
        },
      );
    });
  }

  async lookupEstimateFromSources(params = {}) {
    try {
      let getEstimate = await this.lookupEstimate(
        params,
        this.sqftEstimateSource,
      );
      if (
        getEstimate.adjustedLotSize === 0 &&
        this.sqftEstimateSource !== "zillow" &&
        this.sqftEstimateSource !== "measur-it" &&
        this.zillowFallback
      ) {
        getEstimate = await this.lookupEstimate(params, "zillow");
      }

      this.rgCustomerId = getEstimate.rg_cust_id || null;

      if (getEstimate.forwardPortal) {
        this.redirectToPortal = getEstimate.forwardPortal;
      }
      if (getEstimate) {
        await this.setState(getEstimate);
        await this.setImageState({ ...getEstimate, ...this.state });
      }
      return getEstimate.totalLotSize || 0;
    } catch (e) {
      console.log("estimate source lookup", e);
    }
  }

  lookupEstimate(params, source) {
    const { chatId } = this.state;
    const crmId = "";
    const customerInfo = `&customerName=${`${this.customerFirstName || ""} ${this.customerLastName || ""}`.trim()}&phonenumber=${this.phonenumber}&customerEmail=${
      this.customerEmail
    }`;

    // TODO refactor this
    // biome-ignore lint/suspicious/noAsyncPromiseExecutor: Will be refactored, doing this to avoid distractions
    return new Promise(async (res, rej) => {
      const estimateUrl = `${
        this.dashboardApi
      }/v1/property/estimate/${source}?companyId=${
        this.companyId
      }${crmId}${customerInfo}&botId=${this.botId}&chatId=${chatId}&city=${
        params.customerCity || this.state.customerCity
      }&state=${params.customerState || this.state.customerState}&zip=${
        params.customerZip || this.state.customerZip
      }&address=${params.customerAddress || this.state.customerAddress}`;
      fetch.fetchUrl(estimateUrl, {}, (error, meta, body) => {
        const statusCode = meta.status;
        if (statusCode === 404) {
          res(null);
        } else {
          const str_body = body.toString();
          let jsonBody = {};

          try {
            jsonBody = JSON.parse(str_body);

            let payload = {
              customerLat: 0,
              customerLng: 0,
              totalLotSize: 0,
              adjustedLotSize: 0,
            };

            if (
              this.sqftEstimateSource === "zillow" ||
              this.sqftEstimateSource === "home"
            ) {
              jsonBody = { estimate: { ...jsonBody } };
              jsonBody.estimate.lotSizeSqFt = jsonBody.estimate.area_sq_ft;
              payload.totalLotSize = jsonBody.estimate.area_sq_ft;
            }

            let adjusted = Math.round(
              jsonBody.estimate.lotSizeSqFt * this.sqFtPercentage,
            );
            payload.adjustedLotSize = adjusted;

            // console.log("this is adjusted", this.sqFtPercentage, jsonBody.estimate.lotSizeSqFt, adjusted)

            if (
              (!jsonBody.estimate.zpid &&
                this.sqftEstimateSource !== "zillow") ||
              this.sqftEstimateSource !== "home"
            ) {
              adjusted = jsonBody.estimate.lotSizeSqFt;
            }

            if (jsonBody.estimate.lat) {
              payload.customerLat = jsonBody.estimate.lat;
              payload.customerLng = jsonBody.estimate.lng;
            }

            if (jsonBody.estimate.address) {
              payload = {
                customerLat: jsonBody.estimate.address[0].latitude[0],
                customerLng: jsonBody.estimate.address[0].longitude[0],
                totalLotSize: jsonBody.estimate.lotSizeSqFt,
                adjustedLotSize: adjusted,
              };
            }
            res(payload);
          } catch (e) {
            console.log("error tracking", e);
            const payload = {
              customerLat: 0,
              customerLng: 0,
              totalLotSize: 0,
              adjustedLotSize: 0,
            };

            res(payload);
          }
        }
      });
    });
  }

  setCustomerRecord(customerRecord) {
    return new Promise((res, rej) => {
      if (customerRecord.status === -1) res();
      const record = customerRecord.record;
      const address = customerRecord.estimate.address[0];
      const payload = {
        customerLat: address.latitude[0],
        customerAddress: address.street[0],
        customerCity: address.city[0],
        customerState: address.state[0],
        customerZip: address.zipcode[0],
        customerLng: address.longitude[0],
        customerName: record.customerName,
        customerEmail: record.customerEmail || "unknown@lawnbot.biz",
        adjustedLotSize: Math.round(
          customerRecord.estimate.lotSizeSqFt * this.sqFtPercentage,
        ),
      };
      // this.recordChatRecord(payload);
      this.setImageState(payload);

      this.setState(payload);
      res();
    });
  }

  getLat() {
    return this.state.customerLat;
  }

  getLng() {
    return this.state.customerLng;
  }

  onMessage(event) {
    // Check sender origin to be trusted
    if (
      ![
        "https://draw.testing.measur.it",
        "https://draw.measur.it",
        "https://rgpp.net",
        "https://rgpp.payment.serviceassistant.com",
        "http://127.0.0.1:3001",
      ].includes(event.origin)
    ) {
      return;
    }
    const data = event.data;
    if (data.type === "estimate") {
      this.adjustedLotSize = Math.round(data.value);
      this.recordEstimate(data.value, "sqft", data.meta.Location);
      //  this.recordChatRecord({measurItImage: data.meta.Location});
      this.setState(
        {
          estimateModalOpened: false,
          adjustedLotSize: this.adjustedLotSize,
          measuritImg: data.image,
        },
        async () => {
          await this.updateMeasurImgStep();
          this._bot.current.moveToStep("display-measurit-image");
        },
      );
    } else if (data.type === "unlock") {
    } else if (data.response?.Model) {
      //rgpp
      const model = data.response.Model;
      const payload = {
        transactionId: model.AutopayProfileNumber,
        maskedCard: model.LastFour,
      };
      this.submitPayment("real-green-payment-processing", payload);
    }
  }

  recordEstimate(value, units, image) {
    this.resetTimeoutTime();

    const { chatId } = this.state;

    const dpayload = {
      companyId: this.companyId,
      quoteId: chatId,
      estimate: {
        estimate: value,
        units: units, //'sqft',
        measurItImage: image,
      },
    };

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      payload: JSON.stringify(dpayload),
    };

    const url = `${this.dashboardApi}/v1/quote/estimate${
      this.onboarding ? "?onboarding=true" : this.preview ? "?preview=true" : ""
    }`;

    fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
      if (error) {
        console.log(error);
      } else {
        // if(!chatId) {
        // const record = JSON.parse(body.toString('utf-8'));
        // console.log(record);
        // }
      }
    });
  }

  parseAddress(addressString) {
    return new Promise((res, rej) => {
      na_parser(addressString, (err, address) => {
        if (err) {
          res({});
        } else {
          res(address);
        }
      });
    });
  }

  async componentDidMount() {
    if (window.addEventListener) {
      window.addEventListener("message", this.onMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", this.onMessage, false);
    }

    //set up GA
    setTimeout(() => {
      const tracker = null; //window.ga.getAll()[0];
      //window.ga("require", "ecommerce");
      this.ga = tracker;
    }, 2000);

    this.lookupCompany().then(async () => {
      this.setupAvailableServices();
      this.lookupCustomer();

      // const record = await this.recordChatRecord({});

      const urlParams = new URLSearchParams(window.location.search);
      const params = {};
      for (const p of urlParams.entries()) {
        params[p[0]] = p[1];
      }
      if (params.customerName) {
        const splitName = params.customerName.split(" ");
        params.customerFirstName = splitName[0].trim() || "";
        params.customerLastName = splitName.slice(1).join(" ").trim() || "";
      }

      if (params.source) {
        if (params.source === "contactForm") {
          params.noPII = false;
        }
      }

      if (params.googlelocate) {
        const stripped = params.googlelocate
          .replace(/,\s*USA,\s*/, " ")
          .replace(/%/g, "");
        const isUK = stripped.indexOf(" UK") > -1;
        const isCanada = stripped.indexOf("Canada") > -1;

        // const ap = parser.parseLocation(stripped);
        const na = await this.parseAddress(stripped);

        if (!na.postal_code && isUK) {
          const UKReg =
            /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/gi;
          let matches = stripped.match(UKReg);
          matches = matches.filter((i) => i);
          if (matches.length) {
            na.postal_code = matches[0];
          }
        }

        if (!na.postal_code && isCanada) {
          const postalCodeRegex = /Canada,\s*([A-Za-z-0-9]{3,4})/; // Regex pattern for a standard Canadian postal code
          let matches = stripped.match(postalCodeRegex);
          matches = matches?.filter((i) => i);
          if (matches.length) {
            na.postal_code = matches[1];
          }
        }

        params.customerAddress = `${na.street_address1}`.trim();
        params.customerCity = na.city;
        params.customerState = na.state;
        params.customerZip = na.postal_code;

        // biome-ignore lint/performance/noDelete: <explanation>
        delete params.googlelocate;
      } else if (params.source && params.source === "contactForm") {
        // http://127.0.0.1:8080/?source=contactForm&phonenumber=Shells&customerName=Michelle%20Lysykanycz&customerEmail=lilypondpons%40yahoo.com&customerAddress=294%20Lenape%20Trail&customerZip=18104
      } else {
        if (
          params.customerEmail &&
          params.customerFirstName &&
          params.customerLastName &&
          params.customerAddress &&
          params.customerZip &&
          params.phonenumber
        ) {
          params.noPII = false;
        }
      }

      if (params.customerState?.length && params.customerState.length > 3) {
        params.customerState = translateState(params.customerState);
      }

      if (params.phonenumber) {
        params.phonenumber = params.phonenumber
          .replace(/\s*/g, "")
          .replace(/[(|)]*/gi, "")
          .replace(/-/g, "");
      }

      this.setState(params);

      if (
        params.customerZip &&
        this.dontTaxZipCodes.includes(params.customerZip)
      ) {
        this.serviceTax = 0.0;
        this.lookupZipCodeTax = false;
      } else if (params.customerZip && this.lookupZipCodeTax) {
        this.lookupPostalTaxRate(params.customerZip, params.customerCity);
      }

      if (params.source) {
        if (params.source === "contactForm") {
          this.lookupEstimateFromSources(params);
        }
      }
      this.calculateTotalCosts();
    });
  }

  checkAlreadyQuoted = () => {
    // TODO refactor this
    // biome-ignore lint/suspicious/noAsyncPromiseExecutor: Will be refactored, doing this to avoid distractions
    return new Promise(async (res, rej) => {
      const self = this;
      if (
        ((self.preview && !self.cookiesDisabled) ||
          (!self.preview && !self.onboarding)) &&
        self.crmSolution !== "servman"
      ) {
        const { customerAddress, customerZip } = self.state;
        const buff = Buffer.from(
          (
            (customerAddress ? customerAddress.toLowerCase() : "") +
            (customerZip ? customerZip.toLowerCase() : "")
          )
            .replace(/\s/g, "")
            .replace(/\./g, ""),
        );
        const base64Customer = buff.toString("hex");
        const cookies = document.cookie;
        const foundCookie = cookies
          .split("; ")
          .find((row) => row.startsWith(`${base64Customer}=`));
        const quoteId = foundCookie ? foundCookie.split("=")[1] : null;
        if (quoteId) {
          const requestPayload = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            payload: JSON.stringify({
              quoteId: quoteId,
              companyId: this.companyId,
            }),
          };

          const url = `${this.dashboardApi}/v1/quote/details`;
          await fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
            if (error) {
              console.log(error);
            } else {
              const { quote: result } = JSON.parse(body);
              const convertedSale = result.convertedSale;
              let crmId = null;
              const customer = {};

              if (result.address) {
                if (result.address.customer) {
                  crmId = result?.address?.customer
                    ? result.address.customer.crmId
                    : null;
                  customer.customerName = result.address.customer.name;
                  customer.phonenumber = result.address.customer.phoneNumber;
                  customer.customerEmail = result.address.customer.email;
                  if (result.address.customer.addresses?.items?.[0]) {
                    const quoteAddress =
                      result.address.customer.addresses.items[0];
                    customer.customerState = quoteAddress.state;
                    customer.customerAddress = quoteAddress.address;
                    customer.customerCity = quoteAddress.city;
                    customer.customerZip = quoteAddress.postalCode;
                  }
                }
              }

              let selectedPrograms = result.serviceTypes;
              let selectedServices = result.serviceTypes;
              let priceBlocks = result.recommendedProgams;
              let answers = result.answers;

              selectedPrograms = selectedPrograms
                ? JSON.parse(selectedPrograms)
                : [];

              selectedServices = selectedServices
                ? JSON.parse(selectedServices)
                : [];
              answers = answers ? JSON.parse(answers) : {};
              if (priceBlocks) {
                try {
                  priceBlocks = JSON.parse(priceBlocks);
                } catch (e) {
                  console.log(priceBlocks, e);
                }
              } else {
                priceBlocks = [];
              }

              const quoteEstimate = result.estimate;
              let measuritImg = null;
              let estimate = null;
              if (quoteEstimate) {
                estimate = quoteEstimate.estimate;
                measuritImg = quoteEstimate.measurItImage;
              }

              if (this.crmSolution === "real-green") {
                if (!crmId) {
                } else {
                  this.getPaymentToken(crmId);
                }
              }

              res({
                quoteId,
                crmId,
                customer,
                priceBlocks,
                adjustedLotSize: estimate,
                measuritImg,
                streetImg: measuritImg,
                selectedPrograms,
                selectedServices,
                answers,
                convertedSale,
              });
            }
          });
        } else {
          res({});
        }
      } else {
        res({});
      }
    });
  };

  getPaymentToken = (id) => {
    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      payload: JSON.stringify({
        companyId: this.companyId,
        botId: this.botId,
        amount: 1.0,
        crmId: id,
      }),
    };

    const url = `${this.dashboardApi}/v1/quote/crm/realgreen/payment${
      this.onboarding ? "?onboarding=true" : this.preview ? "?preview=true" : ""
    }`;

    return new Promise((res, rej) => {
      fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
        if (error) {
          console.log(error);
          res(null);
        } else {
          // if(!chatId) {
          const record = JSON.parse(body.toString("utf-8"));
          this.setState({ paymentToken: record.token });
          // console.log("got payment token", record.token);
          res(record.id);
          // }
        }
      });
    });
  };

  resetTimeoutTime() {
    if (this.crmLeadSolution && this.crmLeadSolution === "sales-center") {
      this.crmLeadTimeout.current = new Date().getTime() / 1000;
    }
  }

  startChatRecord = () => {
    this.resetTimeoutTime();

    let dpayload = Object.assign({}, this.buildTrackingPayload(), {});
    dpayload = _.pickBy(dpayload, _.identity);

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      payload: JSON.stringify({
        companyId: this.companyId,
        botId: this.botId,
        customer: {
          ...dpayload,
          customerLastName: dpayload.customerLastName || "",
        },
      }),
    };

    const url = `${this.dashboardApi}/v1/quote/start${
      this.onboarding ? "?onboarding=true" : this.preview ? "?preview=true" : ""
    }`;
    // TODO refactor this
    // biome-ignore lint/suspicious/noAsyncPromiseExecutor: Will be refactored, doing this to avoid distractions
    return new Promise(async (res, rej) => {
      const previousQuote = await this.checkAlreadyQuoted();

      if (!this.onboarding && previousQuote.quoteId) {
        //// we have a previous quote
        this.setState((prevState) => {
          return {
            ...prevState,
            ...previousQuote,
            ...previousQuote.customer,
          };
        });

        res(previousQuote.quoteId);
        return;
      }

      fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
        if (error) {
          if (!this._bot.current) return;
          if (this.crmSolution === "servman") {
            console.log("in error move", error, meta);
            this._bot.current.moveToStep("emergency-intro");
          } else {
            this._bot.current.moveToStep("sqft-too-big");
          }
        } else {
          if (meta.status === 500) {
            if (!this._bot.current) return;
            if (this.crmSolution === "servman") {
              console.log("in 500 move", error, meta);
              this._bot.current.moveToStep("emergency-intro");
            } else {
              this._bot.current.moveToStep("sqft-too-big");
            }
            return;
          }
          // if(!chatId) {
          const record = JSON.parse(body.toString("utf-8"));
          console.log("got record from start", record);
          const { customerAddress, customerZip } = dpayload;
          if (
            (this.preview && !this.cookiesDisabled) ||
            (!this.preview && !this.onboarding)
          ) {
            const buff = Buffer.from(
              (
                (customerAddress ? customerAddress.toLowerCase() : "") +
                (customerZip ? customerZip.toLowerCase() : "")
              ).replace(/\s/g, ""),
            );

            document.cookie = `${buff.toString("hex")}=${
              record.id
            }; expires=${new Date(
              Date.now() + 12096e5,
            )}; SameSite=None; Secure`;
          }

          this.servmanClientInfo = {};
          this.servmanClientInfo.client_id = record.crmId;
          this.servmanClientInfo.contact_id = record.contactId;
          this.servmanClientInfo.branch_id = record.branch_id;

          this.setState({ chatId: record.id, crmId: record.crmId });
          if (this.crmSolution === "real-green") {
            if (!record.crmId) {
              if (record.redirectUrl) {
                this.redirectToPortal = true;
                this.crmPortalUrl = record.redirectUrl;
                this._bot.current.moveToStep("message-redirect-to-portal");
              } else {
                if (this._bot?.current) {
                  this._bot.current.moveToStep("sqft-too-big");
                }
              }
            } else {
              this.getPaymentToken(record.crmId);
              res(record.id);
            }
          } else if (this.crmSolution === "servman") {
            /// let's get the departments/cats
            const sself = this;
            fetch.fetchUrl(
              `${this.dashboardApi}/v1/servman/info`,
              {
                method: "POST",
                payload: JSON.stringify({
                  companyId: this.companyId,
                  botId: this.botId,
                  crmId: record.crmId,
                  branchId: this.servmanClientInfo.branch_id,
                }),
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/json",
                },
              },
              (error, meta, body) => {
                if (error) {
                  console.log(error);
                }
                const record = JSON.parse(body.toString("utf-8"));
                sself.servmanInfo = {};
                sself.servmanInfo.serviceAgreementContent =
                  sself.serviceAgreementContent || "";
                sself.servmanInfo.noServiceAgreementContent =
                  sself.noServiceAgreementContent || "";

                //START set temporary values at random
                // const r = Math.floor(Math.random() * 2) === 0;
                // sself.servmanInfo.serviceAgreementContent = r
                //   ? ""
                //   : "Sample Service Agreement Content Placeholder";
                // sself.servmanInfo.noServiceAgreementContent = r
                //   ? ""
                //   : "Sample No Service Agreement Content Placeholder";
                // END set temporary values at random

                sself.servmanInfo.problems = [];
                sself.servmanInfo.categories = record;
                sself.servmanInfo.branchId = sself.servmanClientInfo.branch_id;

                console.log("got servman info", sself.servmanInfo);

                buildCategoryOptions(sself);
                res(record.id);
              },
            );
          } else {
            res(record.id);
          }
        }
      });
    });
  };

  recordAddressLocation = async (data = {}) => {
    const { chatId } = this.state;
    if (!chatId) {
      const self = this;
      setTimeout(() => {
        self.recordAddressLocation(data);
      }, 400);
      return;
    }

    let dpayload = Object.assign(
      {},
      { companyId: this.companyId, quoteId: chatId },
      data,
    );
    dpayload = _.pickBy(dpayload, _.identity);
    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      payload: JSON.stringify(dpayload),
    };
    const url = `${this.dashboardApi}/v1/quote/location${
      this.onboarding ? "?onboarding=true" : this.preview ? "?preview=true" : ""
    }`;

    return new Promise((res, rej) => {
      fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
        if (error) {
          console.log(error);
          res(null);
        } else {
          // if(!chatId) {
          //   const record = JSON.parse(body.toString('utf-8'));
          //   this.setState({chatId: record.id}, () => {
          //     res(null);
          //   });
          // }
        }
      });
    });
  };

  recordChatRecord = async (data = {}) => {
    this.resetTimeoutTime();

    const { chatId } = this.state;
    if (typeof chatId === "undefined") return;

    let dpayload = Object.assign({}, data);
    dpayload = _.pickBy(dpayload, _.identity);

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      payload: JSON.stringify({
        quoteId: chatId,
        payload: dpayload,
        companyId: this.companyId,
      }),
    };
    const url = `${this.dashboardApi}/v1/quote/record${
      this.onboarding ? "?onboarding=true" : this.preview ? "?preview=true" : ""
    }`;

    return new Promise((res, rej) => {
      fetch.fetchUrl(url, requestPayload, (error, meta, body) => {
        if (error) {
          console.log(error);
          res(null);
        } else {
          if (!chatId) {
            const record = JSON.parse(body.toString("utf-8"));
            this.setState({ chatId: record.id }, () => {
              res(true);
            });
          } else {
            res(true);
          }
        }
      });
    });
  };

  lookupPostalTaxRate(postalCode, city) {
    const taxString = `${postalCode}`;
    if (this.dontTaxZipCodes.indexOf(taxString) !== -1) {
      this.serviceTax = 0.0;
      return;
    }

    const requestPayload = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    const payloadUrl = `${this.dashboardApi}/v1/tax?postalCode=${postalCode}&city=${city}`;

    fetch.fetchUrl(payloadUrl, requestPayload, (error, meta, body) => {
      if (error) {
        console.log(error);
      } else {
        const response = JSON.parse(body.toString("utf-8"));
        if (response.tax === -1) {
          console.log("no tax rate found for this zip");
        } else {
          this.serviceTax = response.tax * 100;
        }
      }
    });
  }

  buildTrackingPayload() {
    const {
      customerLat,
      customerLng,
      customerAddress,
      customerAddress2,
      customerCity,
      customerState,
      customerZip,
      phonenumber,
      customerFirstName,
      customerLastName,
      customerEmail,
    } = this.state;

    return {
      customerFirstName: customerFirstName || "",
      customerLastName: customerLastName || "",
      lat: customerLat,
      lng: customerLng,
      phonenumber: phonenumber,
      customerAddress,
      customerAddress2,
      customerCity,
      customerState,
      customerZip,
      email: customerEmail,
    };
  }

  loadSteps() {
    let compiledSteps = [];

    // biome-ignore lint/complexity/noForEach: <explanation>
    [
      // ...generateIrrigationServiceQuestions(this),
      // ...generateLawnServiceSteps(this),
      ...this.probingQuestionsSteps,

      // ...generatePestServiceSteps(this),
      ...generateObjectionSteps(this),
      ...this.serviceQuestionsSteps,
      ...this.learnMoreSteps,
      ...this.preModalQuestions,
      ...this.objectionSteps,
    ].forEach((i) => {
      let _all = [];

      const definedSteps = (i.steps || []).map((q) => {
        if (!q.message) return q;
        if (
          (q.message instanceof String || typeof q.message === "string") &&
          q.message.indexOf("#name#") > 0
        ) {
          const self = this;
          const msg = q.message;
          const fnc = ({ previousValue, steps }) => {
            const strmessage = msg.replace(
              "#name#",
              self.state.customerFirstName,
            );
            return strmessage;
          };
          q.message = fnc;

          return q;
        }
        return q;
      });

      const gsteps = generateStepTrigger(i, this).map((t) => {
        if (t.options) {
          // biome-ignore lint/performance/noDelete: <explanation>
          delete t.trigger;
        }
        // biome-ignore lint/performance/noDelete: <explanation>
        delete t.conditionals;
        return t;
      });

      _all = [..._all, ...definedSteps, ...gsteps];

      compiledSteps = [...compiledSteps, ..._all];
    });
    const { steps } = this.state;

    this.setState({ steps: [...steps, ...compiledSteps], loading: false });
  }

  updatePriceBlocks(id, value, type = "program") {
    const { priceBlocks, availableServices } = this.state;

    if (type === "program") {
      const newBlocks = priceBlocks.map((i) => {
        if (i.id === id) {
          i.selected = value;
        }
        return i;
      });
      this.setState({ priceBlocks: newBlocks });
    } else if (type === "service") {
      const newBlocks = availableServices.map((i) => {
        if (i.id === id) {
          i.selected = value;
        }
        return i;
      });
      this.setState({ availableServices: newBlocks });
    }
    this.calculateTotalCosts();
  }

  updatePricingTerms(term) {
    this.setState({ term: term });
    this.calculateTotalCosts();
  }

  costLoopV2(detail) {
    // console.log("we got detail", detail);
    if (!detail.length) {
      return {
        appSummary: { cost: 0.0, taxes: 0.0 },
        prePaySummary: { cost: 0.0, taxes: 0.0 },
        appFirstCost: 0.0,
      };
    }

    const summaries = detail
      .filter((i) => i.selected)
      .map((p) => {
        const prePayNetPrice = priceCalculations.getDisplayPricing(
          p,
          "total",
          this.autoPayPricMethod,
          this.autoPayMonthlyPricePeriod,
          this.serviceTax,
          this.lookupZipCodeTax,
        );
        const applicationNetPrice = priceCalculations.getDisplayPricing(
          p,
          "application",
          this.autoPayPricMethod,
          this.autoPayMonthlyPricePeriod,
          this.serviceTax,
          this.lookupZipCodeTax,
        );

        const apps = p.services.application.filter((i) => !i.tooLateToDo);
        const appSummary = _.reduce(
          apps,
          (sum, i) => {
            sum.cost += i.netPrice;
            sum.taxes += i.taxes;
            return sum;
          },
          { cost: 0.0, taxes: 0.0 },
        );

        let appFirstCost = 0.0;
        if (apps.length) {
          appFirstCost = applicationNetPrice;
        }
        const prePay = p.services.prePay.filter((i) => !i.tooLateToDo);
        const prePaySummary = _.reduce(
          prePay,
          (sum, i) => {
            sum.cost += i.netPrice;
            sum.taxes += i.taxes;
            return sum;
          },
          { cost: 0.0, taxes: 0.0 },
        );
        prePaySummary.cost = prePayNetPrice;
        p.appFirstCost = appFirstCost;
        return {
          appSummary,
          prePaySummary,
          appFirstCost,
        };
      });

    const summary = _.reduce(
      summaries,
      (sum, i) => {
        sum.appSummary.cost += i.appSummary.cost;
        sum.appSummary.taxes += i.appSummary.taxes;
        sum.prePaySummary.cost += i.prePaySummary.cost;
        sum.prePaySummary.taxes += i.prePaySummary.taxes;
        sum.appFirstCost += i.appFirstCost;
        return sum;
      },
      {
        appSummary: { cost: 0.0, taxes: 0.0 },
        prePaySummary: { cost: 0.0, taxes: 0.0 },
        appFirstCost: 0.0,
      },
    );
    // console.log('we got results', summary);

    return summary;
  }

  calculateTotalCosts() {
    const { availableServices, priceBlocks } = this.state;

    const pb2 = priceBlocks;
    const av2 = availableServices || [];
    const blockSummary = this.costLoopV2(pb2);
    const serviceSummary = this.costLoopV2(av2);
    const allTotal =
      blockSummary.prePaySummary.cost + serviceSummary.prePaySummary.cost;
    const firstTotal = blockSummary.appFirstCost + serviceSummary.appFirstCost;

    const regularTotal =
      blockSummary.appSummary.cost + serviceSummary.appSummary.cost;

    let discount = 0.0;
    discount = regularTotal - allTotal;
    discount = discount < 0 ? 0.0 : discount;

    if (!regularTotal) {
      discount = 0.0;
    }
    this.setState({ allTotal, firstTotal, regularTotal, discount });
  }

  sendLeadInfo = () => {
    const { chatId } = this.state;
    const chatPayload = {
      chatId,
    };
    if (this.crmSolution === "real-green") {
      chatPayload.rg_cust_id = this.rgCustomerId;
    }

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      payload: JSON.stringify(chatPayload),
    };
    const payloadUrl = `${this.dashboardApi}/v1/company/${this.companyId}/${this.botId}/thinkaboutit${
      this.onboarding ? "?onboarding=true" : this.preview ? "?preview=true" : ""
    }`;

    fetch.fetchUrl(payloadUrl, requestPayload, (error, meta, body) => {
      if (error) {
        console.log(error);
      }
    });
  };

  submitPayment = (type, payload) => {
    const { term, priceBlocks, chatId, charging, availableServices } =
      this.state;
    if (charging) return;
    this.setState({ charging: true });
    // const price = term === 'total' ? allTotal : firstTotal;
    // const taxAmount = price * (this.serviceTax / 100) ;
    // const amount = price + taxAmount;
    // const amount = term === 'application' ? firstTotal : allTotal;

    let expiry = "1 / 2029";

    switch (type) {
      case "lawnbot-credit-card-vault":
        expiry = payload.expiry;
        break;
      case "real-green-payment-processing":
        break;
      default:
      // expiry = payload.exp;
    }

    expiry = expiry.split(/\s*\/\s*/);

    const dashboardPayload = {
      companyId: this.companyId,
      quoteId: chatId,
      expire_month: expiry[0],
      expire_year: expiry[1],
      programs: [],
    };

    /// build dashboard program payload

    const sp = priceBlocks
      .filter((i) => i.selected)
      .map((i) => {
        const pricing = i.pricing.find((p) => p.frequencyType === term);
        const total = i.pricing.find(
          (p) => p.frequencyType === "total",
        ).servicePrice;

        const appPricing = i.pricing.find(
          (p) => p.frequencyType === "application",
        );

        let regAppPricing = 0;
        if (appPricing) {
          regAppPricing =
            typeof appPricing.regularApplicationCost !== "undefined"
              ? appPricing.regularApplicationCost
              : appPricing.servicePrice;
        }

        return {
          programName: i.serviceName,
          applications: i.applicationsRemaining,
          prePayPrice: total,
          totalProgramCost: (i.applicationsRemaining * regAppPricing).toFixed(
            2,
          ),
          firstAppPrice:
            typeof pricing.regularApplicationCost !== "undefined"
              ? pricing.servicePrice
              : pricing.regularApplicationCost,
          applicationPrice: regAppPricing,
          term,
          salesTax: this.serviceTax,
          prePayDiscount: i.prePayDiscount,
          serviceType: i.serviceType,
          pricingMethod: pricing.pricingMethod,
          pricingPeriods: pricing.pricingPeriods,
          services: i.services,
          id: i.id,
        };
      });

    const ss = availableServices
      .filter((i) => i.selected)
      .map((i) => {
        const total = i.pricing.find(
          (p) => p.frequencyType === "total",
        ).servicePrice;
        const pricing = i.pricing.find((p) => p.frequencyType === term);

        const appPricing = i.pricing.find(
          (p) => p.frequencyType === "application",
        );
        const regAppPricing =
          typeof appPricing.regularApplicationCost !== "undefined"
            ? appPricing.regularApplicationCost
            : appPricing.servicePrice;

        return {
          programName: i.serviceName,
          applications: i.applicationsRemaining,
          prePayPrice: total,
          serviceType: i.serviceType,
          totalProgramCost: (i.applicationsRemaining * regAppPricing).toFixed(
            2,
          ),
          firstAppPrice:
            pricing.regularApplicationCost !== "undefined"
              ? pricing.servicePrice
              : pricing.regularApplicationCost,
          applicationPrice:
            typeof pricing.regularApplicationCost !== "undefined"
              ? pricing.regularApplicationCost
              : pricing.servicePrice,
          term,
          salesTax: this.serviceTax,
          prePayDiscount: i.prePayDiscount,
          services: i.services,
          id: i.id,
        };
      });

    const tq = [...sp, ...ss];

    dashboardPayload.programs = tq;

    // const paymentPayload = {email: customerEmail,
    //                         chatId,
    //                         phone: phonenumber, amount,
    //                         selectedPrograms: tq,
    //                         term,
    //                         totalProgramCosts,
    //                         ...payload};

    let paymentUrl = `${this.dashboardApi}/v1/company/${this.companyId}/${this.botId}/payment/card`;

    if (type === "token") {
      paymentUrl = `${this.dashboardApi}/v1/payment/device`;
      dashboardPayload.paymentMethod = "apple-pay";
      dashboardPayload.paymentInfo = payload;
    } else if (type === "lawnbot-credit-card-vault") {
      paymentUrl = `${this.dashboardApi}/v1/secure/payment`;
      dashboardPayload.paymentMethod = "lawnbot-credit-card-vault";
      dashboardPayload.paymentInfo = { token: payload };
    } else if (type === "real-green-payment-processing") {
      paymentUrl = `${this.dashboardApi}/v1/secure/payment`;
      dashboardPayload.paymentMethod = type;
      dashboardPayload.paymentInfo = { ...payload };
    } else {
      /// it's stripe
      dashboardPayload.paymentMethod = "stripe";
      dashboardPayload.paymentInfo = payload;
      paymentUrl = `${this.dashboardApi}/v1/secure/payment`;
      dashboardPayload.botId = this.botId;
    }
    paymentUrl = `${paymentUrl}${
      this.onboarding ? "?onboarding=true" : this.preview ? "?preview=true" : ""
    }`;
    dashboardPayload.term = term;

    const requestPayload = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      payload: JSON.stringify(dashboardPayload),
    };

    fetch.fetchUrl(paymentUrl, requestPayload, (error, meta, body) => {
      if (error || !body) {
        console.log("we got a error from api - stripe");
        this.setState({ charging: false });
      } else {
        try {
          const response = JSON.parse(body.toString("utf-8"));
          if (response.error) {
            alert(response.error);
            this.setState({ charging: false });
          } else {
            this.setState({ charging: false });
            this._modal.current.handleClose();
            this._bot.current.moveToStep("congrats-sale");
          }
        } catch (e) {
          console.log(e);
          console.log("we got a parser error");
          this.setState({ charging: false });
        }
      }
    });
  };

  thinkAboutIt() {
    this._modal.current.handleClose();
    this._bot.current.moveToStep("objection-entry");
  }

  render() {
    const {
      steps,
      priceBlocks,
      term,
      availableServices,
      allTotal,
      firstTotal,
      regularTotal,
      discount,
      loading,
      companyLoading,
      customerAddress,
      customerFirstName,
      customerLastName,
      streetImg,
      aerialImg,
      crmId,
      paymentToken,
    } = this.state;

    return (
      <div>
        {!loading && !companyLoading ? (
          <>
            {this.crmLeadSolution === "sales-center" ? (
              <LeadTimeoutComponent
                formFields={this.state}
                timeoutTime={this.crmLeadTimeout}
                logo={this.companyLogo}
                phone={this.companyPhone}
              />
            ) : null}
            <div>
              <ChatContainer
                style={{
                  border: "1px solid #ccc",
                  padding: 5,
                  borderRadius: 5,
                }}
              >
                <ChatBot
                  steps={steps}
                  className="chatbot"
                  ref={this._bot}
                  headerComponent={
                    <ServiceBotHeader
                      logo={this.companyLogo}
                      tagLine={this.companyTagLine}
                    />
                  }
                  botDelay={this.botSpeed}
                  botAvatar={this.companyAvatar}
                  width={"100%"}
                  height={"100%"}
                  style={{
                    ...this.mobileStyle,
                    boxShadow: "none",
                    paddingRight: 15,
                    boxSizing: "content-box",
                  }}
                  enableSmoothScroll={true}
                  footerStyle={{ display: "none" }}
                  hideUserAvatar={true}
                  bubbleStyle={this.bubbleStyle}
                  handleEnd={(event) => {
                    if (this.willRedirect && this.purchaseMade) {
                      setTimeout(() => {
                        window.top.location.href = this.redirectUrl;
                      }, 2000);
                    }
                  }}
                />
              </ChatContainer>

              <SimpleModal
                ref={this._modal}
                url={
                  this.hideArielAndStreetView ? null : streetImg || aerialImg
                }
                priceBlocks={priceBlocks}
                term={term}
                cardProcessor={this.creditCardPaymentType}
                updateSelection={this.updatePriceBlocks}
                updatePricingTerms={this.updatePricingTerms}
                availableServices={availableServices}
                allTotal={allTotal}
                firstTotal={firstTotal}
                regularTotal={regularTotal}
                discount={discount}
                submitPayment={this.submitPayment}
                customerRecord={{
                  address: customerAddress,
                  name: `${customerFirstName || ""} ${customerLastName || ""}`.trim(),
                }}
                sqft={this.state.adjustedLotSize}
                thinkAboutIt={this.thinkAboutIt}
                companyTeamPic={this.quoteModalImage}
                logo={this.companyLogo}
                serviceTax={this.serviceTax}
                autoPayAvailable={this.autoPayAvailable}
                prePayAvailable={this.prePayAvailable}
                hideSummary={this.hideSummary}
                autoPayAlternativeLabel={this.autoPayAlternativeLabel}
                bypassPayment={this.bypassPayment}
                convertedSale={this.convertedSale}
                autoPayPriceMethod={this.autoPayPriceMethod}
                autoPayMonthlyPricePeriod={this.autoPayMonthlyPricePeriod}
                charging={this.state.charging}
                isLive={this.isLive}
                tos={this.termsOfService}
                crmId={crmId}
                paymentToken={paymentToken}
                isMetric={this.isMetric}
                measurementMode={this.measurementMode}
                company={this.companyId}
                currency={this.currency}
                taxOverride={this.taxOverride}
                lookupZipCodeTax={this.lookupZipCodeTax}
              />

              <MeasureItModal
                company={this.companyId}
                estimate={this.getEstimate}
                modalOpened={this.state.estimateModalOpened}
                address={`${this.state.customerAddress}, ${this.state.customerCity} ${this.state.customerState} ${this.state.customerZip}`}
                isMetric={this.isMetric}
                measurementMode={this.measurementMode}
              />
            </div>
          </>
        ) : (
          <div>
            <Desktop>
              <LoadingScreen
                bgColor="#ffffff"
                spinnerColor="#9ee5f8"
                textColor="#676767"
                logoSrc={this.companyLogo}
                text={this.companyTagLine}
              />
            </Desktop>
            <Mobile>
              <LoadingScreen
                bgColor="#ffffff"
                spinnerColor="#9ee5f8"
                textColor="#676767"
                logoSrc={this.companyLogo}
                text=""
              />
            </Mobile>
            <Tablet>
              <LoadingScreen
                bgColor="#ffffff"
                spinnerColor="#9ee5f8"
                textColor="#676767"
                logoSrc={this.companyLogo}
                text={this.companyTagLine}
              />
            </Tablet>
          </div>
        )}
      </div>
    );
  }
}

export default withCookies(App);
