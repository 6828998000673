import React from "react";
import PropTypes from "prop-types";
import Spinner from "react-spinkit";
import styled from "styled-components";

import Logo from "./Logo";
import Text from "./Text";

const ScreenWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  background: ${(props) => props.bgColor || "#ffffff"};
  opacity: 1;
  visibility: 'visible';
  transition: opacity 0.4s, visibility -0.3s linear 0.5s;
`;
const LoadingComponents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const LoadableData = styled.div`
  display: 'none' ;
`;

const propTypes = {
  bgColor: PropTypes.string,
  spinnerColor: PropTypes.string,
  textColor: PropTypes.string,
  logoSrc: PropTypes.string,
  text: PropTypes.string,
};

function LoadingScreen({
  bgColor,
  spinnerColor,
  textColor,
  logoSrc,
  logoRounded,
  text,
  textStyle,
}) {
  return (
    <div>
      <ScreenWrapper bgColor={bgColor}>
        <LoadingComponents>
          {logoSrc && <Logo src={logoSrc} rounded={logoRounded} />}

          {true && spinnerColor && (
            <Spinner name="ball-beat" fadeIn="quarter" color={spinnerColor} />
          )}

          {text && <Text text={text} textColor={textColor} />}
        </LoadingComponents>
      </ScreenWrapper>

      <LoadableData></LoadableData>
    </div>
  );
}

LoadingScreen.propTypes = propTypes;

export default LoadingScreen;
