import { invalidInput } from "../common/animations";
import { css } from "styled-components";
import React, { Component } from "react";
import { Button } from "@material-ui/core";
import { ThumbUp } from "@material-ui/icons";

import _ from "lodash";

export class PrettyButtonGroup extends Component {
  inputStyle = {
    animation: this.props.invalid
      ? css`
          ${invalidInput} .2s ease
        `
      : "",
    maxWidth: 400,
    marginLeft: 10,
    // minWidth:400,
    width: "98%",
    borderRadius: 10,
    // boxShadow: this.props.invalid ? "inset 0 0 2px #E53935" : "none",
    boxSizing: "border-box",
    color: this.props.invalid ? "#E53935" : "",
    fontSize: 16,
    opacity: this.props.disabled && !this.props.invalid ? ".5" : "1",
    outline: "none",
    padding: this.props.hasButton ? "16px 52px 16px 10px" : "16px 10px",
    WebkitAppearance: "none",
    borderWidth: 2,
    borderStyle: "solid",
    backgroundImage: 'url("/assets/images/send.svg"',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "95% 50%",
  };

  defaultStyle = {
    backgroundColor: "#1565C0",
    color: "white",
    borderRadius: "24px",
    padding: "10px 20px",
    fontSize: "16px",
    minWidth: "150px",
    marginRight: "20px",
    marginBottom: "20px",
  };

  validator = (value) => {
    return true;
  };

  constructor(props) {
    super(props);
    const { dispatchMessage, step, self } = props;
    this.step = step;
    this.self = self;

    if (dispatchMessage) {
      this.dispatchMessage = dispatchMessage.bind(this);
    }
  }

  render() {
    const { buttons = [{ label: "Yes", value: "yes", icon: <ThumbUp /> }] } =
      this.props;

    return (
      <div
        className="pretty-button"
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {buttons
          .filter((ii) => ii.label)
          .map((button, index) => {
            let buttonStyle = button.style || this.defaultStyle;
            buttonStyle = {
              ...buttonStyle,
              marginBottom: 20,
              flexBasis: "33%",
            };
            return (
              <Button
                onClick={(e) => this.handleClick(button)}
                key={index + "button_" + button.value}
                size="large"
                variant="outlined"
                color="primary"
                style={buttonStyle}
                startIcon={button.icon ? button.icon : null}
              >
                {button.label}
              </Button>
            );
          })}
        <div style={{ display: "flex" }}></div>
      </div>
    );
  }

  handleClick = async (button) => {
    const { triggerNextStep } = this.props;
    if (this.step.setVariable) {
      this.self.answers[this.step.setVariable] = button.value;
    }
    triggerNextStep({ value: button.value, sendUserMessage: true });
  };
}
