import React from "react";
import { makeStyles, Button } from "@material-ui/core";
import PlanSwitch from "../../plan-switch.component";
import QuotePriceBlock from "../components/quote.price-block.component";
import QuoteHeader from "../components/quote.header.component";
import PriceSummary from "../components/quote.price-summary.component";
import FlipMove from "react-flip-move";

export default function QuotePage1(props) {
  const { disableAnimate } = props;

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignContent: "center",
      flexWrap: "wrap",
      fontFamily: `'Open Sans', sans-serif`,
      paddingBottom: 20,
    },
    header: {
      // backgroundColor: '#ccc',
      margin: "auto",
      // display: 'flex',
      // alignContent: 'center',
      // flexDirection: 'column'
    },
    headerText: {
      textAlign: "center",
      marginBottom: 5,
      lineHeight: 1,
    },
    imageView: {
      borderRadius: props.radius || 15,
      height: props.height || 300,
      width: props.width || 600,
    },
    yearly: {
      display: "flex",
      flexDirection: "row",
      alignContent: "center",
      margin: "auto",
      marginTop: 15,
      marginBottom: 15,
    },
  }));

  const classes = useStyles();

  const getPriceBlocks = () => {
    const key = `blocka_${Math.floor(Math.random() * 150000)}`;

    return (
      <div key={key} style={{ margin: "auto" }}>
        {props.priceBlocks.map((i) => {
          const key2 = `blockb_${Math.floor(Math.random() * 150000)}`;
          return <QuotePriceBlock key={key2} {...i} {...props} />;
        })}
      </div>
    );
  };

  const getQuotePricing = () => {
    const key = `blockc_${Math.floor(Math.random() * 150000)}`;
    return (
      <div className="quote_pricing_block" key={key}>
        <PriceSummary {...props} />
      </div>
    );
  };

  const getCompanyImage = () => {
    const key = `blockd_${Math.floor(Math.random() * 150000)}`;
    return (
      <div key={key}>
        <img
          alt={"company"}
          src={props.companyTeamPic}
          style={{ width: "100%" }}
        />
      </div>
    );
  };

  const getSelectedServices = () => {
    const key = `blocke_${Math.floor(Math.random() * 150000)}`;
    if (!props.availableServices) return null;
    return (
      <div style={{ paddingTop: 5 }} key={key}>
        {props.availableServices
          .filter((i) => i.selected)
          .map((i) => {
            const key = "block-key-" + Math.round(Math.random() * 1000000);
            return (
              <QuotePriceBlock
                key={key}
                {...i}
                {...props}
                blockType={"service"}
              />
            );
          })}
      </div>
    );
  };
  const getAvailableServices = () => {
    if (!props.availableServices) return null;
    const key = `blockf_${Math.floor(Math.random() * 150000)}`;
    return (
      <div key={key} style={{ paddingTop: 20, margin: "auto" }}>
        {props.availableServices
          .filter((i) => !i.selected)
          .map((i) => {
            const key = "quotebloc_" + Math.floor(Math.random() * 200000);
            return (
              <QuotePriceBlock
                {...i}
                {...props}
                blockType={"service"}
                key={key}
              />
            );
          })}
      </div>
    );
  };

  const helpYou = () => {
    const showBelow = props.availableServices.filter((i) => !i.selected);
    if (!showBelow.length) return null;

    return (
      <span
        style={{
          textAlign: "center",
          marginTop: 10,
          fontSize: 26,
          fontFamily: "Open Sans",
          fontWeight: 800,
        }}
      >
        What else can we do for you?
      </span>
    );
  };

  const iNeedToThinkAboutIt = () => {
    return !props.convertedSale ? (
      <div
        onClick={props.thinkAboutIt}
        style={{
          borderRadius: 30,
          backgroundColor: "#1565C0",
          color: "white",
          fontFamily: `'Open Sans', sans-serif`,
          fontSize: 19,
          cursor: "pointer",
          width: "80%",
          margin: "auto",
          textAlign: "center",
          padding: 20,
          height: 31,
          fontWeight: "bolder",
          marginBottom: 30,
          marginTop: 30,
        }}
      >
        I NEED TO THINK ABOUT IT{" "}
        <span role="img" aria-label="Thinking">
          🤔
        </span>
      </div>
    ) : null;
  };

  const displayGuide = () => {
    return (
      <div className={classes.container}>
        <div
          className={classes.serviceItem}
          style={{
            fontSize: 14,
            paddingTop: 8,
            paddingLeft: 12,
            paddingRight: 15,
          }}
        >
          <span role="img" aria-label="face">
            👨‍
          </span>
          Based on your selections we recommend these services
        </div>
      </div>
    );
  };

  const displayTOS = () => {
    return !props.tos ? null : (
      <div className={classes.container} style={{ backgroundColor: "#F4F4F4" }}>
        <div
          className={classes.serviceItem}
          style={{
            fontSize: 14,
            paddingTop: 8,
            paddingLeft: 12,
            paddingRight: 15,
            maxWidth: "80%",
            width: "80%",
            textAlign: "center",
          }}
        >
          <b style={{ marginBottom: 10, fontSize: 14 }}>
            Payment Terms and Conditions
          </b>
          <br />
          <div style={{ textAlign: "center" }}>{props.tos}</div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ margin: "auto" }}>
      <QuoteHeader {...props} key={"quote_header"} />
      <div className={classes.container}>
        <div className={classes.yearly}>
          <PlanSwitch {...props} key={"plan_switch"} />
        </div>
        {displayGuide()}
        <FlipMove
          duration={500}
          delay={0}
          staggerDelayBy={15}
          staggerDurationBy={20}
          appearAnimation={disableAnimate ? "none" : "accordionHorizontal"}
          enterAnimation="accordionHorizontal"
          leaveAnimation="accordionHorizontal"
          easing="linear"
          maintainContainerHeight={true}
          key={"move_flip"}
        >
          {getPriceBlocks()}
          {getSelectedServices()}
        </FlipMove>

        {!props.hideSummary ? getQuotePricing() : null}
        {getCompanyImage()}
        {displayTOS()}
        {helpYou()}
        <FlipMove
          duration={500}
          delay={0}
          staggerDelayBy={15}
          staggerDurationBy={20}
          appearAnimation={disableAnimate ? "none" : "accordionHorizontal"}
          enterAnimation="accordionHorizontal"
          leaveAnimation="accordionHorizontal"
          easing="linear"
          maintainContainerHeight={true}
          key={"move_flip1"}
        >
          {getAvailableServices()}
        </FlipMove>

        {iNeedToThinkAboutIt()}
      </div>
    </div>
  );
}
