import {
  Fireplace,
  InfoRounded,
  ThumbDown,
  ThumbUpSharp,
} from "@material-ui/icons";
import { HtmlMessageComponent } from "../components/html-message.component";
import { NoInputComponent } from "../components/step-components/NoInputComponent";
import { InlineUserInput } from "../components/inline-user-input.component";
import { PrettyButtonGroup } from "../components/pretty-button-group.component";
import AppointmentScheduler from "../components/scheduling.component";
import { saveOrder } from "../services/servmanService";

const servManSteps = (self) => {
  return [
    {
      id: "ask-is-this-an-emergency",
      message: () => {
        return `Is this ${
          self.emergencyTellMeMoreRetry ? "still" : ""
        } an emergency that requires immediate attention?`;
      },
      trigger: "is-this-an-emergency",
    },
    {
      id: "is-this-an-emergency",
      component: (
        <PrettyButtonGroup
          step={this}
          self={self}
          buttons={[
            {
              value: "Yes",
              label: "Yes",
              icon: <ThumbUpSharp />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "No",
              label: "No",
              icon: <ThumbDown />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "Tell Me More",
              label: "Tell Me More",
              icon: <InfoRounded />,
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
          ]}
        />
      ),
      asMessage: false,
      waitAction: true,
      setVariable: "Emergency",
      trigger: () => {
        if (self.answers["Emergency"] === "Tell Me More") {
          self.emergencyTellMeMoreRetry = true;
          return "emergency-tell-me-more";
        }

        if (self.answers["Emergency"] === "Yes") {
          return "emergency-intro";
        }
        return "ask-service-type";
      },
    },
    {
      id: "emergency-tell-me-more",
      message: () => {
        self.emergencyTellMeMoreRetry = true;
        return self.emergencyTellMeMore;
      },
      trigger: () => {
        return "ask-is-this-still-an-emergency";
      },
    },
    {
      id: "ask-is-this-still-an-emergency",
      message: () => {
        return `Is this still an emergency that requires immediate attention?`;
      },
      trigger: "is-this-still-an-emergency",
    },
    {
      id: "is-this-still-an-emergency",
      component: (
        <PrettyButtonGroup
          step={this}
          self={self}
          buttons={[
            {
              value: "Yes",
              label: "Yes",
              icon: <ThumbUpSharp />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "No",
              label: "No",
              icon: <ThumbDown />,
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
          ]}
        />
      ),
      asMessage: false,
      waitAction: true,
      setVariable: "Emergency",
      trigger: () => {
        if (self.answers["Emergency"] === "Tell Me More") {
          self.emergencyTellMeMoreRetry = true;
          return "emergency-tell-me-more";
        }

        if (self.answers["Emergency"] === "Yes") {
          return "emergency-intro";
        }
        return "ask-service-type";
      },
    },
    {
      id: "ask-service-type",
      message: "What is the type of service you’re looking to schedule today?",
      trigger: () => {
        return "get-category-question";
      },
    },
    {
      id: "ask-do-you-have-a-service-agreement",
      message: "Do you have a service agreement?",
      trigger: () => {
        return "do-you-have-a-service-agreement";
      },
    },
    {
      id: "do-you-have-a-service-agreement",
      component: (
        <PrettyButtonGroup
          step={this}
          self={self}
          buttons={[
            {
              value: "Yes",
              label: "Yes",
              icon: <ThumbUpSharp />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "No",
              label: "No",
              icon: <ThumbDown />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "Tell Me More",
              label: "Tell Me More",
              icon: <InfoRounded />,
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
          ]}
        />
      ),
      asMessage: false,
      waitAction: true,
      setVariable: "Service.Contract",
      trigger: () => {
        if (self.answers["Service.Contract"] === "Tell Me More") {
          return "service-agreement-tell-me-more";
        }

        return "fetch-category-questions";
      },
    },
    {
      id: "service-agreement-tell-me-more",
      message: () => {
        self.serviceAgreementTellMeMoreRetry = true;
        return self.serviceAgreementTellMeMore;
      },
      trigger: () => {
        return "ask-do-you-have-a-service-agreement-again";
      },
    },
    {
      id: "ask-do-you-have-a-service-agreement-again",
      message: "Do you have a service agreement?",
      trigger: () => {
        return "do-you-have-a-service-agreement-again";
      },
    },
    {
      id: "do-you-have-a-service-agreement-again",
      component: (
        <PrettyButtonGroup
          step={this}
          self={self}
          buttons={[
            {
              value: "Yes",
              label: "Yes",
              icon: <ThumbUpSharp />,
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "No",
              label: "No",
              icon: <ThumbDown />,
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
          ]}
        />
      ),
      asMessage: false,
      waitAction: true,
      setVariable: "Service.Contract",
      trigger: () => {
        if (self.answers["Service.Contract"] === "Tell Me More") {
          return "service-agreement-tell-me-more";
        }

        return "fetch-category-questions";
      },
    },
    {
      id: "fetch-category-questions",
      component: (
        <NoInputComponent
          dispatchMessage={async () => {
            const category = self.servmanInfo.categories.find((category) => {
              return category.name === self.servmanInfo.selectedCategory;
            });

            try {
              const problems = await self.lookupCategoryProblems({
                companyId: self.companyId,
                botId: self.botId,
                categoryId: category.id,
                branchId: self.servmanInfo.branchId,
                contractRelated: self.answers["Service.Contract"],
              });
              self.servmanInfo.problems = problems.data;

              const options = self.servmanInfo.problems.map((q) => {
                return {
                  value: q.name,
                  label: q.name,
                  style: {
                    backgroundColor: "#1565C0",
                    color: "white",
                    borderRadius: "24px",
                    padding: "10px 20px",
                    fontSize: "16px",
                    minWidth: "150px",
                    marginRight: "20px",
                  },
                };
              });

              self._bot.current.addStepToStack({
                id: "get-category-problem",
                component: <PrettyButtonGroup buttons={options} step={{}} />,
                waitAction: true,
                asMessage: false,
                trigger: "record-question",
              });
            } catch (e) {
              console.log(
                `Failed async call to lookupCategoryQuestions:\n${e}`,
              );
            }
          }}
          message={""}
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: () => {
        if (self.servmanInfo.problems.length === 0) {
          return "emergency-intro";
        }

        if (
          (self.answers["Service.Contract"] === "Yes" &&
            self.servmanInfo.serviceAgreementContent !== "") ||
          (self.answers["Service.Contract"] === "No" &&
            self.servmanInfo.noServiceAgreementContent !== "")
        ) {
          return "show-service-contract-content";
        }
        return "ask-category-question";
      },
    },
    {
      id: "show-service-contract-content",
      message: () => {
        if (self.answers["Service.Contract"] === "Yes") {
          return self.servmanInfo.serviceAgreementContent;
        } else {
          return self.servmanInfo.noServiceAgreementContent;
        }
      },
      //asMessage: false,
      trigger: "ask-category-question",
    },
    {
      // Does not appear to be in use
      id: "ask-category-no",
      message: "What is the nature of your problem?",
      trigger: ({ previousValue, steps }) => {
        self.servmanInfo["serviceAgreement"] = "no";
        self.answers["serviceAgreement"] = "no";
        return "get-category-question";
      },
    },
    {
      id: "record-question",
      message: ({ previousValue }) => {
        self.servmanInfo.selectedQuestion = previousValue;
        self.answers["Selected.Question"] = previousValue;

        const question = self.servmanInfo.problems.find((problem) => {
          return problem.name === self.servmanInfo.selectedQuestion;
        });

        if (question) {
          self.answers["Selected.Question"] = question.name;
        } else {
          self.answers["Selected.Question"] =
            "questionId = " + self.servmanInfo.selectedQuestion;
        }

        return `We're happy to help you with the scheduling of your appointment.`;
        // if (!question) {
        //   return "We understand...";
        // } else {
        //   let servmanKey = "servman-question-" + question.id;
        //   let hasQuestion = self.state.steps.find(
        //     item => item.id === servmanKey
        //   );

        //   if (!hasQuestion) {
        //     return "We understand...";
        //   } else {
        //     return `We’re happy to help you with scheduling your service appointment`;
        //   }
        // }
      },
      trigger: ({ previousValue, steps }) => {
        const question = self.servmanInfo.problems.find((problem) => {
          return problem.name === self.servmanInfo.selectedQuestion;
        });

        if (!question) {
          return "emergency-intro";
        } else {
          const servmanKey = "servman-question-" + question.id;
          const hasQuestion = self.state.steps.find(
            (item) => item.id === servmanKey,
          );

          const servmanProbing = self.state.steps.find(
            (item) => item.id === "servman-entry",
          );

          if (hasQuestion) {
            return servmanKey;
          } else {
            if (servmanProbing) {
              return "servman-entry";
            }
            return "ask-additional-information";
          }
        }
      },
    },
    {
      id: "ask-additional-information",
      message: "Please tell us more about the service you’re looking for",
      trigger: "get-additional-information",
    },
    {
      id: "get-additional-information",
      component: (
        <InlineUserInput
          placeholder={"Additional Information"}
          type={"text"}
          validator={(value) => {
            return true;
          }}
          customButton={true}
          defaultInputMessage={"Send"}
        />
      ),
      waitAction: true,
      sendUserMessage: true,
      dispatchMessage: (value) => {
        if (value && value !== "null") {
          self.servmanInfo["additional.Informationrmation"] = value;
          self.answers["additional.Informationrmation"] = value;
        }
      },
      trigger: "schedule-time",
    },
    {
      id: "ask-category-question",
      message: () => {
        return `Please tell us more about your ${self.answers["selected.Category"]} request.`;
      },
      trigger: () => {
        return "get-category-problem";
      },
    },
    {
      id: "schedule-time",
      component: (
        <NoInputComponent
          showLoading={true}
          disappearAfter={true}
          dispatchMessage={async () => {
            await new Promise((res) => {
              setTimeout(() => {
                res(true);
              }, 2000);
            });
          }}
          asHTML={true}
          message={`<div style="display:flex; flex-direction: column; justify-content: center; align-items: flex-start; ">
          <div style="color: #ccc; font-size: 18px; margin-top:10px;"><i>We are checking our system for our available technicians...</i></div><br />
          <div style="color: #ccc; font-size: 18px; margin-top:10px;"><i>We are now pulling all available times for you to choose from. Please select a time.</i></div></div>`}
        />
      ),
      asMessage: false,
      waitAction: true,
      trigger: () => {
        const question = self.servmanInfo.problems.find((problem) => {
          return problem.name === self.servmanInfo.selectedQuestion;
        });

        if (!question || question.allow_scheduling === true) {
          return "ask-time";
        } else {
          return "unschedulable";
        }
      },
    },
    {
      id: "schedule-confirmed",
      message: ({ previousValue }) => {
        if (!previousValue) return "no data";
        console.log(self.customSchedulingVerbiage);
        const message = `${previousValue.day} ${new Date(
          previousValue.date,
        ).toLocaleDateString()}`;

        // customSchedulingVerbiage
        // Your appt has been booked for  <<Scheduled Date>> during the  <<Service Window Desc>>
        let serviceMessage = self.customSchedulingVerbiage;
        serviceMessage = serviceMessage.replace("<<Scheduled Date>>", message);
        serviceMessage = serviceMessage.replace(
          "<<Service Window Desc>>",
          `${previousValue.service_window_desc}`,
        );

        return serviceMessage;
      },
      trigger: "tos-yes",
    },
    {
      id: "ask-agree-tos",
      message: "Do you agree to our terms of service?",
      trigger: "display-tos",
    },
    {
      id: "display-tos",
      message: ({ previousValue, steps }) => {
        return self.termsOfService;
      },
      trigger: "tos-yes-no",
    },
    {
      id: "tos-yes-no",
      component: (
        <PrettyButtonGroup
          buttons={[
            {
              value: "Yes",
              label: "Yes",
              style: {
                backgroundColor: "#1565C0",
                color: "white",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
            {
              value: "No",
              label: "No",
              style: {
                backgroundColor: "white",
                color: "#1565C0",
                borderRadius: "24px",
                padding: "10px 20px",
                fontSize: "16px",
                minWidth: "150px",
                marginRight: "20px",
              },
            },
          ]}
          step={this}
          self={self}
        />
      ),
      waitAction: true,
      asMessage: false,
      trigger: "tos-yes-no-response",
    },
    {
      id: "tos-yes-no-response",
      message: ({ previousValue, steps }) => {
        self.tosAccept = previousValue;
        if (previousValue === "yes") {
          return "Great";
        } else {
          return "Sorry, we can't schedule an appointment without your agreement.";
        }
      },
      trigger: ({ previousValue, steps }) => {
        if (self.tosAccept === "yes") {
          return "tos-yes";
        } else {
          return "emergency-intro";
        }
      },
    },
    {
      id: "unschedulable",
      message: () => {
        const question = self.servmanInfo.problems.find((problem) => {
          return problem.name === self.servmanInfo.selectedQuestion;
        });

        saveOrder(
          self.dashboardApi,
          self.companyId,
          self.botId,
          self.servmanClientInfo,
          question,
          self.answers,
        );
        return "We will be reaching out to follow up on your request soon!";
      },
      end: true,
    },
    {
      id: "tos-yes",
      message: "We'll see you soon!",
      end: true,
    },
    {
      id: "tos-no",
      message:
        "Sorry, we can't schedule an appointment without your agreement.",
      trigger: "emergency-intro",
    },
    {
      id: "emergency-intro",
      message: `To best assist you please call our office directly at ${self.companyPhone}.`,
      end: true,
    },
  ];
};

const buildCategoryOptions = (self) => {
  const options = [];

  self.servmanInfo.categories.forEach((category) => {
    options.push({
      value: category.name,
      label: category.name,
      style: {
        backgroundColor: "#1565C0",
        color: "white",
        borderRadius: "24px",
        padding: "10px 20px",
        fontSize: "16px",
        minWidth: "150px",
        marginRight: "20px",
      },
    });
  });

  self._bot.current.addStepToStack({
    id: "get-category-question",
    component: <PrettyButtonGroup buttons={options} step={{}} self={self} />,
    waitAction: true,
    asMessage: false,
    setVariable: "selected.Category",
    trigger: () => {
      self.servmanInfo.selectedCategory = self.answers["selected.Category"];
      return "ask-do-you-have-a-service-agreement";
    },
  });

  self._bot.current.addStepToStack({
    id: "ask-time",
    component: (
      <AppointmentScheduler
        tos={self.termsOfService}
        clientInfo={self.servmanClientInfo}
        getServmanInfo={() => self.servmanInfo}
        servmanUrl={self.dashboardApi}
        companyId={self.companyId}
        botId={self.botId}
        getAnswers={() => self.answers}
      />
    ),
    waitAction: true,
    asMessage: false,
    trigger: () => {
      return "schedule-confirmed";
    },
  });

  self._bot.current.addStepToStack({
    id: "prompt-time",
    message:
      "Thank you for sharing additional information with us. Please select a time from the calendar below",
    trigger: "ask-time",
  });
};

export default servManSteps;
export { buildCategoryOptions };
